<template>
  <v-data-table
    :headers="headers"
    :items="arItems"
    :loading="loading"
    dense
    disable-sort
    disable-pagination
    hide-default-footer
  >
    <template #[`item.id`]="{ item }">
      <v-simple-checkbox
        :disabled="dn ? item.quantity < 1 : item.quantity_balance < 1"
        :ripple="false"
        :value="selected.includes(item.id)"
        @input="onSelect(item.id)"
      />
    </template>

    <template #[`item.offer`]="{ item }">
      <product-preview :product-id="item.offer.product_id" />
    </template>

    <template #[`item.total_with_tax`]="{ item }">
      <price-viewer
        :currency="item.currency"
        :label="true"
        :value="item.total_with_tax"
        chip
      />
    </template>

    <template #[`item.price`]="{ item }">
      <price-viewer
        :currency="item.currency"
        :label="true"
        :value="item.price"
        chip
      />
    </template>

    <template #[`item.cfe_code`]="{ item }">
      <span v-text="`${item.cfe_code.description} (${item.cfe_code.code})`" />
    </template>

    <template #[`item.invoice.created_at`]="{ item }">
      {{ $dayjs(item.invoice.created_at).format("L LT") }}
    </template>

    <template #[`item.invoice.total_with_tax`]="{ item }">
      <price-viewer
        :currency="item.invoice.currency"
        :label="true"
        :value="item.invoice.total_with_tax"
        chip
      />
    </template>

    <template #[`item.invoice.order_number`]="{ item }">
      <span class="font-weight-bold" v-text="item.invoice.order_serial" />
      <span class="ml-2" v-text="item.invoice.order_number" />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import type { InvoicePositionData } from "@planetadeleste/vue-mc-gw";
import { InvoiceMovementType } from "@planetadeleste/vue-mc-gw";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";

import ProductPreview from "@/modules/products/components/ProductPreview.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import type { InvoiceMovementTypeCode } from "@/types/utils";
import { isArray } from "lodash";

@Component({
  components: { ProductPreview, PriceViewer },
})
export default class ReferencePositionsTable extends Mixins(DataTableMixin) {
  @VModel({ type: Array, default: () => [] }) selected!: number[];

  @Prop([Array, String]) readonly items!: InvoicePositionData[] | string;

  /**
   * @var {boolean} dn Debit Note type
   */
  @Prop(Boolean) readonly dn!: boolean;

  /**
   * @var {number} movementCode Invoice movement code
   */
  @Prop(Number) readonly movementCode!: InvoiceMovementTypeCode;

  arHeaders: DataTableHeader[] = [];

  get loading() {
    return this.items === "loading";
  }

  get arItems() {
    return isArray(this.items) ? this.items : [];
  }

  mounted() {
    this.$nextTick(() => {
      if (this.movementCode === InvoiceMovementType.CODE_RECEIPT_CORRECT) {
        this.arHeaders = [
          { text: "cfecode", value: "cfe_code" },
          { text: "tax.percent", value: "tax_percent" },
          { text: "fee", value: "price" },
          { text: "value", value: "total_with_tax" },
        ];
      } else if (this.movementCode === InvoiceMovementType.CODE_CANCEL_DEBT) {
        this.arHeaders = [
          { text: "", value: "id", sortable: false },
          { text: "date", value: "invoice.created_at" },
          {
            text: "invoice.dgi.number",
            value: "invoice.order_number",
            sortable: false,
          },
          { text: "invoice.type", value: "invoice.cfe_name", sortable: false },
          { text: "total.invoice", value: "invoice.total_price_value" },
        ];
      } else {
        this.arHeaders = [
          { text: "", value: "id", sortable: false },
          { text: "product", value: "offer", sortable: false },
          { text: "quantity", value: "quantity", sortable: false },
          {
            text: "quantity.balance",
            value: "quantity_balance",
            sortable: false,
          },
          { text: "unit.price", value: "price", sortable: false },
          { text: "amount", value: "total_with_tax", sortable: false },
        ];
      }

      this.setDTHeaders(this.arHeaders);
    });
  }

  onSelect(iPositionId: number) {
    const iIndex = this.selected.indexOf(iPositionId);

    if (iIndex === -1) {
      this.selected.push(iPositionId);
    } else {
      this.selected.splice(iIndex, 1);
    }

    this.$emit("change", this.selected);
    this.$forceUpdate();
  }

  onSelected(iPositionId: number) {
    const bVal = this.selected.includes(iPositionId);

    return bVal;
  }
}
</script>
